import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { ThemeProvider } from '@emotion/react'
import { ApolloProvider } from '@apollo/client/index.js'
import { I18nProvider } from 'react-aria'

import { mTheme } from './style/themes'
import { createGraphqlClient } from './graphql/apollo.client'

startTransition(() => {
  let app = (
    <StrictMode>
      <ThemeProvider theme={mTheme}>
        <I18nProvider locale="en-GB">
          <ApolloProvider client={createGraphqlClient()}>
            <RemixBrowser />
          </ApolloProvider>
        </I18nProvider>
      </ThemeProvider>
    </StrictMode>
  )

  hydrateRoot(document, app)
})
